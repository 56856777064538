

figure {
  margin: 0;
}

li {
  margin-bottom: 10px;
}

html {
  overflow: hidden;
  width: 100%;
}

body {
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

ion-modal {
  z-index: 2000000001;
}

video {
  width: 235px;
}